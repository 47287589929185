/* src/components/Loading.css */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;



    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.loading-container img {
    max-width: 80%; /* Adjust this value based on your preference */
    max-height: 80%; /* Adjust this value based on your preference */
    width: auto;
    height: auto;
}
