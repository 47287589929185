/* src/components/Statistics.css */

.statistics {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto new lines */
  justify-content: space-around;
  background-color: #4753be;
  color: #0f151c;
  padding: 2em 0;
  text-align: center;
}

.stat-item {
  flex: 1;
  margin: 0 1em;
  min-width: 150px; /* Ensure items don't get too small */
}

.stat-icon {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.stat-number {
  font-size: 2.5em;
  font-weight: bold;
}

.stat-label {
  font-size: 1.2em;
}

@media (max-width: 1024px) {
  .stat-item {
    margin: 0 0.5em;
  }

  .stat-icon {
    font-size: 1.8em;
  }

  .stat-number {
    font-size: 2.2em;
  }

  .stat-label {
    font-size: 1.1em;
  }
}

@media (max-width: 768px) {
  .stat-item {
    flex: 1 0 100%; /* Make each item take full width */
    margin: 0.5em 0;
  }

  .stat-icon {
    font-size: 1.6em;
  }

  .stat-number {
    font-size: 2em;
  }

  .stat-label {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .stat-item {
    margin: 0.5em 0;
  }

  .stat-icon {
    font-size: 1.4em;
  }

  .stat-number {
    font-size: 1.8em;
  }

  .stat-label {
    font-size: 0.9em;
  }
}
