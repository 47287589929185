.info {
  text-align: center;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.info h2 {
  color: #ffffff;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.info p {
  color: #ffffff;
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.info-section {
  margin-bottom: 2rem;
  text-align: left;
}

.info-section h3 {
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.info-section p,
.info-section ul {
  color: #e9ecef;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.info-section ul {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.info-section img {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin: 1rem 0;
}

.footer {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

.footer p {
  margin: 0;
  font-size: 1rem;
  color: #6c757d;
}
