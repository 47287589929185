/* src/components/CallUsButton.css */
.call-us-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #25d366; /* Change to your preferred color */
    color: white;
    padding: 10px 20px;
    border-radius: 50px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    text-decoration: none;
    font-size: 16px;
  }
  
  .call-us-button:hover {
    background-color: #1da851; /* Change to your preferred hover color */
    color: white;
  }
  