/* Add this to your cover page CSS file */
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-fadeInUp {
    opacity: 0;
    animation: fadeInUp 1s ease-out forwards;
  }
  
  .animate-fadeInUp-delay {
    opacity: 0;
    animation: fadeInUp 1s ease-out forwards;
    animation-delay: 0.5s; /* Add delay for the second element */
  }
  
.cover-page {
    height: 120vh; /* Full height of the viewport */
    background-image: url('C:\Users\JAMES RAYS 123\Desktop\react\elegant\src\components\hem.avif'); /* Replace with your background image path */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.4); /* Dark overlay for better text visibility */
    width: 100%;
    height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .cover-content {
    text-align: center;
    color: #ffffff;
    z-index: 1;
  }
  
  .cover-content h1 {
    color: #ffffff;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .cover-content p {
    color: #ffffff;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .cover-buttons .btn {
    padding: 0.75rem 1.5rem;
    margin: 0 0.5rem;
    text-transform: uppercase;
    text-decoration: none;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    color: white;
    border-radius: 5px;
  }
  
  .btn:hover {
    opacity: 0.8;
  }
  