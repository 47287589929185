/* Contact Info Styles */
.contact-info {
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.contact-info h3 {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #333;
}

.contact-info p {
  font-size: 1em;
  color: #555;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.contact-info p i {
  margin-right: 10px;
  color: #28a745; /* Green color for icons */
}

.contact-info a {
  color: #007bff; /* Blue color for email link */
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Footer Styles */
.footer {
  background:none;
  color: #fff;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
  border-radius: 10px;
}

.footer p {
  margin: 0;
}

.footer a {
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
}

.footer a:hover {
  color: #ccc;
}
/* Location Section Styles */
.location {
  color: #fff;
  background:none;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  text-align: center;
}

.location h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.map-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.map-container iframe {
  border: 0;
  border-radius: 10px;
}
.courses h1 {
  color: #ffffff;
}
.courses h3{
  color: #ffffff;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.courses p{
  color: #ffffff;
}

@media (max-width: 768px) {
  .map-container iframe {
    height: 300px; /* Adjust height for tablets */
  }
}

@media (max-width: 480px) {
  .map-container iframe {
    height: 200px; /* Adjust height for mobile devices */
  }
}
.student-carousel .student-photo {
  width: 200px; /* Adjust the size as needed */
  height: auto;
  border-radius: 50%;
  margin: 0 auto;
}
.student-carousel .carousel-caption p {
  position: static;
  padding-top: 20px;
  color: #f7f7f7;
}
.student-carousel {
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
  padding: 40px 20px;
  position: relative;
  color: #fff;
}
.student-carousel h2 {
  color: #f7f7f7;
  font-size: 2em;
  margin-bottom: 20px;
}

  