body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.6;
  background-color: #f8f9fa;
}

.content {
  padding: 20px;
}

h1, h2, h3 {
  color: #333;
  margin-bottom: 20px;
}

p {
  color: #555;
  margin-bottom: 20px;
}

a {
  text-decoration: none;
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .content {
    padding: 10px;
  }
}
