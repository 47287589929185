/* src/components/Header.css */

header {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
              url('C:\Users\JAMES RAYS 123\Desktop\react\elegant\src\components\dbackk.jpg') no-repeat center center;
  background-size: cover;
  color: #ffffff;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
  padding-bottom: 0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5rem 1rem;
}

.logo-title {
  display: flex;
  align-items: center;
}

.logo {
  max-width: 150px;
  height: auto;
  margin-right: 0.5rem;
}

.title {
  font-size: 1.5rem;
  color: #ffffff;
}

.nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.nav-item {
  color: #ffffff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
}

@media (max-width: 768px) {
  .container {
    flex-direction: row; /* Changed from column to row */
    text-align: left; /* Changed from center to left */
  }

  .logo-title {
    margin-bottom: 0; /* Remove margin for better alignment */
  }

  .nav {
    flex-direction: row; /* Changed from column to row */
  }

  .nav-item {
    padding: 0.5rem 1rem; /* Adjust padding to be consistent with desktop */
    font-size: 1.2rem; /* Font size to match desktop */
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.2rem;
  }

  .nav-item {
    font-size: 1rem; /* Slightly larger font size for better readability */
  }
}
