/* GermanLanguage.css */
.course-details {
  display: flex;
  flex-wrap: wrap; /* Allows content to wrap on smaller screens */
  align-items: flex-start;
  padding: 2em;
}

.course-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  margin-right: 2em;
}

.course-content {
  flex: 1;
  text-align: left;
}
.course-content h2{
  color: rgb(255, 255, 255);
}

.course-content h3 {
  color: rgb(255, 255, 255);
  margin-top: 1em;
}
.course-content p{
  color: aliceblue;
}

.btn-primary {
  background-color: #1c4a7c;
  color: rgb(255, 255, 255);
  padding: 0.5em 1em;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1em;
  display: inline-block;
  margin-top: 1em;
}

.btn-primary:hover {
  background-color: #007bff;
}

.navigation-links {
  margin-top: 2em;
  list-style: none;
  padding: 0;
}

.navigation-links li {
  margin-bottom: 1em;
}

.navigation-links a {
  color: #66d9ed;
  text-decoration: none;
  font-size: larger;
}

.navigation-links a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .course-details {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center align items */
  }

  .course-image {
    margin-right: 0; /* Remove margin on smaller screens */
    margin-bottom: 1em; /* Add some space below the image */
  }

  .course-content {
    width: 100%;
    text-align: center; /* Center text on smaller screens */
  }
}
