@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Playfair Display', serif;
  background: url('C:/Users/JAMES RAYS 123/Desktop/react/elegant/src/components/dbback.avif') no-repeat center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  color: #fff;
  overflow-x: hidden;
}

.home {
  margin: 0;
  padding: 0;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0; /* Ensure there's no margin or padding */
}

.hero-content h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #fff;
}

.hero-content h2 {
  color: #fff;
}

.hero-content h3 {
  color: #f7f7f7;
}

.hero-content p {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #f7f7f7;
}

.carousel {
  width: 100%;
  max-height: 70vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.carousel img {
  width: 100%;
  height: auto;
  border-radius: 0;
}

.carousel-item {
  height: 70vh;
}

.carousel-item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding: 20px;
}

.carousel-caption h3 {
  color: #05036a;
}

.carousel-caption h2 {
  color: #030645;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.carousel-caption p {
  color: #050743;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.carousel-caption .btn {
  font-size: 1em;
  padding: 10px 20px;
}

.featured-course {
  text-align: center;
  padding: 20px;
  background-color: none;
}

.featured-course h2 {
  color: #007bff;
  font-size: 2em;
  margin-bottom: 20px;
}

.featured-course p {
  color: aliceblue;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.featured-course .btn {
  font-size: 1em;
  padding: 10px 20px;
}

.home-courses {
  text-align: center;
  padding: 40px 20px;
}

.home-courses h2 {
  color: #f7f7f7;
  font-size: 2em;
  margin-bottom: 20px;
}

.course-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.course-item {
  width: 300px;
  background-color: #134f94;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.course-item:hover {
  transform: scale(1.05);
}

.course-item img {
  width: 100%;
  border-radius: 10px;
}

.course-item h3 {
  font-size: 1.5em;
  margin-top: 10px;
  color: #f7f7f7;
}

.course-item p {
  font-size: 1em;
  color: #f7f7f7;
}

.student-experiences {
  text-align: center;
  padding: 20px;
  background-color: none;
}

.student-experiences h2 {
  color: aliceblue;
  font-size: 2em;
  margin-bottom: 20px;
}

.location {
  text-align: center;
  padding: 20px;
}

.location h2 {
  color: #f7f7f7;
  font-size: 2em;
  margin-bottom: 20px;
}

.map-container {
  width: 100%;
  height: 450px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
}

.footer {
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
  padding: 20px;
}

.footer-content {
  max-width: 1000px;
  margin: 0 auto;
  color: #f7f7f7;
}

.footer p {
  margin: 10px 0;
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-icons a {
  color: #f7f7f7;
  font-size: 1.5em;
}

.footer-icons a:hover {
  color: #007bff;
}

/* Contact Styles */
.contact {
  padding: 20px;
}

.contact h2 {
  color: #fff;
  font-size: 2em;
  margin-bottom: 20px;
}

.contact p {
  color: #fff;
  font-size: 1.1em;
  margin-bottom: 20px;
}

.contact-form {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: inline-block;
  font-size: 1em;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  color: #555;
}

.btn-submit {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.btn-submit:hover {
  background-color: #0056b3;
}

.contact-info {
  background: #f4f4f4;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-info h3 {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #333;
  font-weight: bold;
}

.contact-info p {
  font-size: 1em;
  color: #555;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.contact-info p i {
  margin-right: 15px;
  color: #28a745;
  font-size: 1.2em;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2em;
  }

  .carousel-caption h3 {
    font-size: 1.2em;
  }

  .carousel-caption p,
  .featured-course p {
    font-size: 1em;
  }

  .course-grid {
    flex-direction: column;
    align-items: center;
  }

  .course-item {
    width: 100%;
  }

  .contact-form {
    padding: 10px;
  }

  .contact-info {
    padding: 15px;
  }

  .contact-info h3 {
    font-size: 1.5em;
  }

  .contact-info p {
    font-size: 0.9em;
  }

  .contact-info p i {
    font-size: 1em;
  }
}
