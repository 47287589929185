@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Playfair Display', serif;
  background: url('C:\Users\JAMES RAYS 123\Desktop\react\elegant\src\components\dbackk.jpg');
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  color: #fff;
  overflow-x: hidden;
}

.home {
  margin-top: 50px;
  margin: 0;
  padding: 0;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.hero-content h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #fff;
}

.hero-content h2, .hero-content h3, .hero-content p {
  color: #f7f7f7;
}

.hero-content p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.carousel {
  width: 100%;
  max-height: 70vh;
  overflow: hidden;
  margin-top: 0;
  padding-top: 0;
  position: relative;
}

.carousel img {
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}

.carousel-item {
  height: 70vh;
  position: relative;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding: 20px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.carousel-caption h2 {
  color: #030645;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.carousel-caption h3, .carousel-caption p {
  color: #050743;
}

.carousel-caption .btn {
  font-size: 1em;
  padding: 10px 20px;
}

.home-courses {
  text-align: center;
  padding: 40px 20px;
}

.home-courses h2 {
  color: #f7f7f7;
  font-size: 2em;
  margin-bottom: 20px;
}

.course-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.course-item {
  width: 300px;
  background-color: #06111e;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.course-item:hover {
  transform: scale(1.05);
}

.course-item img {
  width: 100%;
  border-radius: 10px;
}

.course-item h3 {
  font-size: 1.5em;
  margin-top: 10px;
  color: #f7f7f7;
}

.course-item p {
  font-size: 1em;
  color: #f7f7f7;
}

.student-experiences, .location {
  text-align: center;
  padding: 10px;
}

.student-experiences h2, .location h2 {
  color: aliceblue;
  font-size: 2em;
  margin-bottom: 20px;
}

.map-container {
  width: 100%;
  height: 450px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
}

.footer {
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
  padding: 20px;
}

.footer-content {
  max-width: 1000px;
  margin: 0 auto;
  color: #f7f7f7;
}

.footer p {
  margin: 10px 0;
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-icons a {
  color: #f7f7f7;
  font-size: 1.5em;
}

.footer-icons a:hover {
  color: #007bff;
}

/* Responsive styles */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 1.8em; /* Slightly smaller for better mobile view */
  }

  .carousel-caption h3 {
    font-size: 1.1em;
  }

  .carousel-caption p,
  .featured-course p {
    font-size: 0.9em;
  }

  .course-grid {
    flex-direction: column;
    align-items: center;
  }

  .course-item {
    width: 90%; /* Make it a bit narrower for better padding on small screens */
  }

  .hero-content img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin: 0.5rem 0;
  }

  /* Adjust the carousel item to show the important parts of the image */
  .carousel-item img {
    object-fit: cover;
    object-position: center top; /* Adjusted to ensure visibility of important parts */
  }

  /* Ensure visibility of the woman in the image by adjusting the object-position */
  .carousel-item.active img {
    object-position: center; /* Adjust as needed to focus on the desired part of the image */
  }
}

/* Adjust the size of text and spacing to better fit on smaller devices */
.featured-course h2, .home-courses h2, .student-carousel h2 {
  font-size: 1.5em;
}

/* Student Carousel Specific Styles */
.student-carousel {
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
  padding: 40px 20px;
  position: relative;
  color: #fff;
}

.student-carousel .student-photo {
  width: 100px;
  height: 100px;
  border-radius: 20%;
  margin: 0 auto;
}

.student-carousel .carousel-caption p {
  position: static;
  padding-top: 20px;
  color: #f7f7f7;
}

.student-carousel h2 {
  color: #f7f7f7;
  font-size: 2em;
  margin-bottom: 20px;
}

/* New CSS for fixing the caption issue on mobile view */
@media only screen and (max-width: 768px) {
  /* Ensure the student photo stays on top */
  .student-experience-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content */
  }

  /* Keep the student photo above the caption */
  .student-photo {
    order: -1;
  }

  /* Ensure the caption is below the student photo */
  .student-carousel .carousel-caption {
    order: 1;
    text-align: center;
    padding: 10px;
  }

  .student-carousel .carousel-caption p {
    color: #f7f7f7;
  }
}
