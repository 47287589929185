/* src/components/FeaturedCourse.css */

.featured-course {
  background-color: #454091;
  padding: 3rem 2rem;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.1);
  margin: 2rem auto;
  max-width: 1200px;
  text-align: center;
}

.featured-course h2 {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
}

.featured-course p {
  font-size: 1.25rem;
  color: #000000;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.featured-course .btn {
  padding: 0.75rem 2rem;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: #2980b9;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.featured-course .btn:hover {
  background-color: #3498db;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .featured-course {
    padding: 2.5rem 1.5rem;
  }

  .featured-course h2 {
    font-size: 2.25rem;
  }

  .featured-course p {
    font-size: 1.15rem;
  }

  .featured-course .btn {
    padding: 0.7rem 1.8rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 992px) {
  .featured-course {
    padding: 2rem 1rem;
  }

  .featured-course h2 {
    font-size: 2rem;
  }

  .featured-course p {
    font-size: 1.1rem;
  }

  .featured-course .btn {
    padding: 0.65rem 1.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .featured-course {
    padding: 1.5rem 1rem;
  }

  .featured-course h2 {
    font-size: 1.75rem;
  }

  .featured-course p {
    font-size: 1rem;
  }

  .featured-course .btn {
    padding: 0.6rem 1.3rem;
    font-size: 0.95rem;
  }
}

@media (max-width: 576px) {
  .featured-course {
    padding: 1rem 0.75rem;
  }

  .featured-course h2 {
    font-size: 1.5rem;
  }

  .featured-course p {
    font-size: 0.95rem;
  }

  .featured-course .btn {
    padding: 0.55rem 1rem;
    font-size: 0.85rem;
  }
}
